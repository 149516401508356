import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import "element-plus/theme-chalk/index.css"

function setRemUnit() {
  const docEl = document.documentElement
  const clientWidth = docEl.clientWidth

  if (!clientWidth) return

  // 设置根元素的字体大小，假设设计稿宽度为750px
  // const baseSize = 75; // 750 / 10 = 75
  let fontSize = clientWidth / 10
  // 限制最大页面宽度为750px
  if (clientWidth > 750) {
    fontSize = 750 / 18
  } else if (clientWidth >= 500) {
    // 折叠屏
    fontSize = clientWidth / 18
  }

  // 设置一个最小字体大小，防止在小屏幕上字体过小
  const minFontSize = 12 // 你可以根据需要调整
  if (fontSize < minFontSize) {
    fontSize = minFontSize
  }

  docEl.style.fontSize = fontSize + "px"
}
// 初始化
setRemUnit()

createApp(App).use(router).mount("#app")
