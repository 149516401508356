import { createRouter, createWebHistory } from "vue-router"
const routes = [
  { path: "/", component: () => import("../views/DownloadPage.vue") },
  // sdk下载页面
  { path: "/download", component: () => import("../views/DownloadPage.vue") },
  //sdk下载详情
  { path: "/detail/:id", component: () => import("../views/DetailPage.vue") },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
